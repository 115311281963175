body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.input-field-custom {
  font-family: 'Raleway', sans-serif;
  /*text-transform: uppercase;*/
  color: #000;
  background-color: #ffffff;
  padding: 14px 14px;
  letter-spacing: 1px;
  margin: 6px;
  font-size: 15px;
  font-weight: 500;
  border-radius: 52px;
  /*transition: all 0.5s linear;*/
  border: 0;
  /*height: 46px;*/
  /*line-height: 1.3333333;*/
}

.form-custom {
  font-family: 'Raleway', sans-serif;
  color: #000;
  background-color: #ffffff;
  border-radius: 25px;
  border: 0;
  /*height: 52px;*/
  /*line-height: 1.3333333;*/
  width: fit-content;
  align-content: center;
  padding: 6px;
    margin: auto;
}



.subscription-container {
  font-family: 'Raleway', sans-serif;
  font-size: 15px;
  font-weight: 500;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subscription-content {
  width: 360px;
  height: 56px;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, .2);
  border-radius: 60px;
  overflow: hidden;
}

.subscription {
  position: relative;
  width: 100%;
  height: 100%;
}

.subscription .add-email{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 0 20px;
}

.subscription .submit-email {
  position: absolute;
  top: 0;
  right: 0;
  height: calc(100% - 2px);
  width: 120px;
  border: none;
  border-radius: 60px;
  outline: none;
  margin: 1px;
  padding: 0 20px;
  cursor: pointer;
  background-color: #5ca9fb;
  background-image: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  color: #FFFFFF;
  transition: width .35s ease-in-out,
  background .35s ease-in-out;
}

.subscription.done .submit-email {
  width: calc(100% - 2px);
  background-color: #5ca9fb;
  background-image: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
}

.subscription .submit-email .before-submit,
.subscription .submit-email .after-submit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  align-content: center;
  line-height: 38px;
  transition: visibility .35s ease-in-out,
  opacity .35s ease-in-out;
  text-transform: uppercase;
}

.subscription.done .submit-email .before-submit,
.subscription:not(.done) .submit-email .after-submit {
  visibility: hidden;
  opacity: 0;
}

.subscription .submit-email .after-submit {
  transition-delay: .35s;
}

.subscription:not(.done) .submit-email .before-submit,
.subscription.done .submit-email .after-submit {
  visibility: visible;
  opacity: 1;
}
